<template>
  <UsersList />
</template>

<script>
  export default {
    components: {
      UsersList: () => import('@/components/people/users/UsersList')
    }
  }
</script>